import { render } from "./ScheduleSetting.vue?vue&type=template&id=769a1967&scoped=true"
import script from "./ScheduleSetting.vue?vue&type=script&lang=ts"
export * from "./ScheduleSetting.vue?vue&type=script&lang=ts"

import "./ScheduleSetting.vue?vue&type=style&index=0&id=769a1967&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-769a1967"

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QSelect});
