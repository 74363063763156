<template>
  <div class="q-mt-md">
    <div class="q-mt-md title">スケジュール</div>
    <div class="row q-mt-sm sub-title">指定のシナリオの開始orゴール到達起点に何日後に配信するか設定してください</div>
    <div class="row q-mt-md">
      <div class="col-1">
        <label class="text-grey-9 text-bold">{{ $t('label.story.scenario') }} </label>
      </div>
      <div class="col-11">
        <q-input outlined dense class="col" disable v-model="previousCampaign.title"></q-input>
      </div>
    </div>

    <div class="row q-mt-md">
      <div class="col-1">
        <label class="text-grey-9 text-bold">{{ $t('label.story.starting_point') }}</label>
      </div>
      <div class="col-11">
        <q-select
          outlined
          dense
          option-value="id"
          option-label="title"
          v-model="type"
          :options="startingPointTypeList"
          emit-value
          map-options
        />
      </div>
    </div>
    <div class="row q-mt-md">
      <div class="col-1">
        <label class="text-grey-9 text-bold">{{ $t('label.push.delay_days') }}</label>
      </div>
      <div class="col-11">
        <q-input
          v-model="value.delay_day"
          dense
          outlined
          type="number"
          class="text-h6"
          :rules="[(val) => val >= 0 || requiredRule.delayDays]"
        />
      </div>
    </div>
    <div class="row q-mt-md">
      <div class="col-1">
        <label class="text-grey-9 text-bold">{{ $t('label.story.time') }}</label>
      </div>
      <div class="col-11">
        <q-input
          dense
          outlined
          class="text-h6"
          v-model="value.time"
          type="time"
          :rules="[(val) => (val && val.length > 0) || requiredRule.time]"
          style="position: relative"
          ref="inputRefTime"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IInitialCampaign, IPushCampaign, ISchedule } from '@/utils/types'
import PushCampaignMixin from '@/components/push-campaign/mixins/PushCampaignMixin.vue'
import { STARTING_POINT_TYPE } from '@/utils/constants'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue', 'update:onChangeStartingPoint'],
})
export default class ScheduleSetting extends mixins(PushCampaignMixin) {
  @Prop({
    default: {
      schedule_type: '',
      schedule_by_type: '',
      delay_day: 0,
      day_of_week: 0,
      day_of_month: 1,
      date: '',
      time: '',
    },
  })
  modelValue!: ISchedule

  @Prop()
  startingPoint!: string

  @Prop()
  previousCampaign!: IPushCampaign | IInitialCampaign

  type = ''

  get startingPointTypeList() {
    const options = [
      {
        id: STARTING_POINT_TYPE.SCENARIO_START,
        // title: this.$t('label.push.one_time'),
        title: this.$t('label.story.scenario_start_time'),
      },
      {
        id: STARTING_POINT_TYPE.SCENARIO_COMPLETED,
        // title: this.$t('label.push.one_time'),
        title: this.$t('label.story.scenario_goal_arrival_time'),
      },
    ]
    return options
  }

  get requiredRule() {
    const requiredRule = {
      delayDays: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.push.delay_days'),
      }),
      time: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.push.time'),
      }),
    }
    return requiredRule
  }

  get value() {
    return this.modelValue
  }

  set value(value: ISchedule) {
    this.$emit('update:modelValue', value)
  }

  @Watch('type', { immediate: true })
  async onChangeStartingPoint() {
    this.$emit('update:onChangeStartingPoint', this.type)
  }

  created() {
    this.type = this.startingPoint
  }
}
</script>
<style scoped lang='scss'>
.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 23.17px;
  text-align: left;
}
.sub-title {
  font-size: 12px;
  font-weight: 400;
}
</style>
