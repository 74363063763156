
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { Prop, Watch } from 'vue-property-decorator'
import { SCENARIO_MAKER } from '@/utils/constants'
import { ICard } from 'bot-flow-maker/src/types'
import {
  EScenarioResourceType,
  IGoal,
  IInitialCampaign,
  IMessage,
  IPushCampaign,
  IQuestion,
  ISelectedItemCondition,
  IVersionHistory,
} from '@/utils/types'
import QuestionFormModal from '@/components/question/QuestionFormModal.vue'
import MessagesFormModal from '@/components/messages/MessagesFormModal.vue'
import GoalFormModal from '@/components/goal/GoalFormModal.vue'
import { ACTION_GOAL, ACTION_MESSAGES, ACTION_QUESTION, VERSION_HISTORY } from '@/store/actions'
import PreviewSelectorModal from '@/components/scenarios/selectors/PreviewSelectorModal.vue'
import HistorySelectorModal from '@/components/scenarios/selectors/HistorySelectorModal.vue'
import moment from 'moment-timezone'
import StartPushFormModal from '@/components/story/common/StartPushFormModal.vue'
import { v4 as uuidv4 } from 'uuid'

@Options({
  components: {
    StartPushFormModal,
    QuestionFormModal,
    MessagesFormModal,
    GoalFormModal,
    PreviewSelectorModal,
    HistorySelectorModal,
  },
  directives: { maska },
  emits: [
    'update:modelValue',
    'update:editingMode',
    'update:dateRange',
    'update:onChangeDirection',
    'update:onChangeScenarios',
    'update:editingStart',
    'update:openStartSettings',
    'update:campaignValue',
    'updateStartSettings',
  ],
})
export default class ScenariosMaker extends Vue {
  @Prop({ default: [] })
  modelValue!: ICard[]

  @Prop({ default: false })
  isReport!: boolean

  @Prop({ default: '' })
  campaignId!: string

  @Prop({ default: false })
  isTestMode!: boolean

  @Prop({ default: '0' })
  directionType!: string

  @Prop({ default: false })
  isCopy!: boolean

  @Prop()
  campaign!: IInitialCampaign | IPushCampaign

  @Prop()
  campaignType!: string

  @Prop()
  selectedPushIndex!: number

  @Prop()
  previousCampaign!: IPushCampaign | IInitialCampaign

  @Prop()
  isOpenStartSettings!: boolean

  @Prop()
  isStoryFirstPush!: boolean

  newCampaignId = uuidv4()
  modalStartVisible = false
  modalQuestionlVisible = false
  modalMessageVisible = false
  modalGoalVisible = false
  modalPreviewVisible = false
  modalHistoryVisible = false

  selectedItemCondition: ISelectedItemCondition = {}
  selectedEditItem: IQuestion | IMessage | IGoal = {}
  titleLastHistory = ''
  isEditWelcome = false
  labelAllUser = ''

  get currentCampaignId() {
    if (!this.campaignId) {
      return this.newCampaignId
    }
    return this.campaignId
  }

  get value(): ICard[] {
    return this.modelValue
  }

  set value(val: ICard[]) {
    this.$emit('update:modelValue', val)
  }

  get appId() {
    return this.$route?.params?.app_id
  }

  get messages() {
    return this.$store.getters.messages
  }

  get questions() {
    return this.$store.getters.questions
  }

  get goals() {
    return this.$store.getters.goals
  }

  get openStartSettings() {
    return this.isOpenStartSettings
  }

  set openStartSettings(value: boolean) {
    this.$emit('update:openStartSettings', value)
  }

  @Watch('campaignId', { immediate: true })
  async appIdChanged() {
    this.titleLastHistory = ''
    await this.$store.dispatch(ACTION_QUESTION.LOAD_ITEMS, {
      app_id: this.appId,
    })

    await this.$store.dispatch(ACTION_GOAL.LOAD_ITEMS, {
      app_id: this.appId,
    })

    await this.$store.dispatch(ACTION_MESSAGES.LOAD_ITEMS, {
      app_id: this.appId,
    })
    if (!this.isReport) {
      const currentVersion: IVersionHistory = await this.$store.dispatch(VERSION_HISTORY.LOAD_ITEM_LATEST, {
        app_id: this.appId,
        campaign_id: this.campaignId,
      })
      if (currentVersion && currentVersion._id) {
        const input: string = currentVersion?.default_title + ''
        const format = 'YYYY-MM-DD HH:mm:ss'
        const zone = 'Asia/Tokyo'
        const time = moment(moment.tz(input, format, zone).utc()).fromNow()

        this.titleLastHistory = this.$t('label.last_edit_was', { lastModified: time })
      }
    }
  }

  iframeURL() {
    let isBlankPush = true
    if (this.campaignType === 'push') {
      const push: IPushCampaign = this.campaign
      if (this.isTestMode) {
        if (push.test_detail?.schedule?.time && push.test_detail?.schedule?.delay_day) {
          isBlankPush = false
        }
      } else {
        if (push.prod_detail?.schedule?.time && push.prod_detail?.schedule?.delay_day) {
          isBlankPush = false
        }
      }
    }
    return this.$router.resolve({
      name: 'story_scenario_maker',
      params: {
        appId: this.appId,
        campaignId: this.currentCampaignId,
        isReport: this.isReport,
        isTestMode: this.isTestMode,
        campaignType: this.campaignType,
        index: this.selectedPushIndex,
      },
      query: {
        isBlankPush: isBlankPush,
      },
    })?.fullPath
  }

  // eslint-disable-next-line
  postToIFrame(action: string, data: any) {
    // eslint-disable-next-line
    const iframeEl = document.getElementById('myFrame-' + this.campaignId) as any
    if (!iframeEl) {
      return
    }

    iframeEl.contentWindow.postMessage(
      {
        action,
        data: {
          data: JSON.stringify(data),
          isTestMode: this.isTestMode,
        },
      },
      '*'
    )
  }

  handleIFrameReady() {
    let isStartNode = false
    let isWelcomeNode = false
    if (this.value) {
      const startCards = this.value.filter((item) => item.id === EScenarioResourceType.start)
      if (startCards && startCards.length > 0) {
        isStartNode = true
      }
      const welcomeCards = this.value.filter((item) => item.uniqueId === EScenarioResourceType.welcome)
      if (welcomeCards && welcomeCards.length > 0) {
        isWelcomeNode = true
      }
    }

    if (!isStartNode) {
      const startCard: ICard = {
        id: EScenarioResourceType.start,
        uniqueId: EScenarioResourceType.start,
        displayId: '',
        // title: this.$t('label.start'),
        title: 'スタート',
        titleBadge: '',
        cardType: EScenarioResourceType.start,
        left: 100,
        top: 100,
        answers: [],
        totalUsers: 0,
        labelTotalUsers: '',
        isHideDeleteBtn: true,
      }
      if (this.campaignType === 'initial') {
        startCard.isHideAddBtn = true
        startCard.isHideEditBtn = true
        startCard.answers = [
          {
            id: 'any',
            // title: this.$t('label.any'),
            title: this.labelAllUser,
            nextCards: [
              {
                nodeIndex: 1,
                uniqueId: EScenarioResourceType.welcome,
              },
            ],
            totalUsers: 0,
          },
        ]
      }
      this.value.push(startCard)
    }
    if (!isWelcomeNode && this.campaignType === 'initial') {
      const welcomeCard: ICard = {
        id: '',
        uniqueId: EScenarioResourceType.welcome,
        displayId: '',
        // title: this.$t('label.welcome'),
        title: 'ウェルカム',
        titleBadge: '',
        cardType: EScenarioResourceType.message,
        left: 100,
        top: 300,
        answers: [
          {
            id: 'any',
            // title: this.$t('label.any'),
            title: this.labelAllUser,
            nextCards: [],
            totalUsers: 0,
          },
        ],
        totalUsers: 0,
        labelTotalUsers: '',
        isHideDeleteBtn: true,
      }
      this.value.push(welcomeCard)
    }
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.CURRENT_VALUE, this.value)
  }

  handleUpdateDataFromIFrame(event) {
    const data = JSON.parse(event?.data?.data)
    const id = event?.data?.campaignId
    if (id === this.currentCampaignId) {
      this.value = data
      this.$emit('update:onChangeScenarios', data)
    }
  }

  onChangeDirection(val) {
    this.$emit('update:onChangeDirection', val)
  }

  onCloseModal() {
    // Edit modal
    this.modalStartVisible = false
    this.modalQuestionlVisible = false
    this.modalMessageVisible = false
    this.modalGoalVisible = false
    this.modalPreviewVisible = false
    this.modalHistoryVisible = false
    this.openStartSettings = false
  }

  updateStartSettings(value: IPushCampaign) {
    this.$emit('updateStartSettings', value)
  }

  editCardDone(resource) {
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.UPDATE_CARD, { resource })
  }

  addCardDone(card) {
    if (this.selectedItemCondition.cardType === EScenarioResourceType.message || !this.selectedItemCondition.cardType) {
      this.onSelectNextMessageDone(card)
    } else if (this.selectedItemCondition.cardType === EScenarioResourceType.question) {
      this.onSelectNextQuestionDone(card)
    } else if (this.selectedItemCondition.cardType === EScenarioResourceType.goal) {
      this.onSelectNextGoalDone(card)
    }
  }

  onCardEdit(uniqueId) {
    if (uniqueId === EScenarioResourceType.start) {
      if (!this.isStoryFirstPush) {
        this.modalStartVisible = true
      }
    } else {
      const card = this.value.find((item) => item.uniqueId === uniqueId)
      if (card) {
        if (card.cardType === EScenarioResourceType.question) {
          this.selectedEditItem = this.questions.find((item) => item._id === card.id) || {}
          this.modalQuestionlVisible = true
        } else if (card.cardType === EScenarioResourceType.message) {
          this.selectedEditItem = this.messages.find((item) => item._id === card.id) || {}
          this.modalMessageVisible = true
        } else if (card.cardType === EScenarioResourceType.goal) {
          this.selectedEditItem = this.goals.find((item) => item._id === card.id) || {}
          this.modalGoalVisible = true
        }
        this.isEditWelcome = card.uniqueId === EScenarioResourceType.welcome
      }
    }
  }

  onOpenPreview() {
    this.modalPreviewVisible = true
  }

  onOpenHistory() {
    this.modalHistoryVisible = true
  }

  onSelectHistoryDone(item: IVersionHistory) {
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.ITEM_HISTORY, item)
  }

  addCard(resource, cardType: string, selectedItemCondition: ISelectedItemCondition) {
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.ADD_CARD, { resource, cardType, selectedItemCondition })
  }

  openSelectNextQuestion(selectedItemCondition: ISelectedItemCondition) {
    this.selectedEditItem = {
      app_id: this.appId,
    }
    this.selectedItemCondition = selectedItemCondition
    this.modalQuestionlVisible = true
  }

  openSelectNextMessage(selectedItemCondition: ISelectedItemCondition) {
    this.selectedEditItem = {
      app_id: this.appId,
    }
    this.selectedItemCondition = selectedItemCondition
    this.modalMessageVisible = true
  }

  openSelectNextGoal(selectedItemCondition: ISelectedItemCondition) {
    this.selectedEditItem = {
      app_id: this.appId,
    }
    this.selectedItemCondition = selectedItemCondition
    this.modalGoalVisible = true
  }

  onSelectNextQuestionDone(question: IQuestion) {
    this.addCard(question, EScenarioResourceType.question, this.selectedItemCondition)
    this.onCloseModal()
  }

  onSelectNextMessageDone(message: IMessage) {
    this.addCard(
      message,
      this.isEditWelcome ? EScenarioResourceType.welcome : EScenarioResourceType.message,
      this.selectedItemCondition
    )
    this.onCloseModal()
  }

  onSelectNextGoalDone(goal: IGoal) {
    this.addCard(goal, EScenarioResourceType.goal, this.selectedItemCondition)
    this.onCloseModal()
  }

  handleMessage(event) {
    const action = event?.data?.action
    if (action === SCENARIO_MAKER.FROM_IFRAME.READY) {
      this.handleIFrameReady()
      this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.DIRECTION, this.directionType)
      if (!this.isReport) {
        this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.MSG_LAST_HISTORY, this.titleLastHistory)
      }
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.IFRAME_NEW_DATA) {
      this.handleUpdateDataFromIFrame(event)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.IS_EDITING) {
      this.$emit('update:editingMode', !!event?.data.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.DATE_RANGE) {
      this.$emit('update:dateRange', event?.data.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.ON_CARD_EDIT) {
      this.onCardEdit(event?.data?.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.ON_OPEN_PREVIEW) {
      this.onOpenPreview()
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.OPEN_SELECT_NEXT_QUESTION) {
      this.openSelectNextQuestion(event?.data?.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.OPEN_SELECT_NEXT_MESSAGE) {
      this.openSelectNextMessage(event?.data?.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.OPEN_SELECT_NEXT_GOAL) {
      this.openSelectNextGoal(event?.data?.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.ON_CHANGE_DIRECTION) {
      this.onChangeDirection(event?.data?.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.ON_OPEN_HISTORY) {
      this.onOpenHistory()
    }
  }

  handleErrorCard(uniqueId) {
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.ERROR_CARD, uniqueId)
  }

  handleKeyPress(event) {
    if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
      this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.CTRL_Z, event)
    }
  }

  @Watch('isCopy')
  async onDetailCopy() {
    let scenarios: ICard[]
    if (this.isTestMode) {
      scenarios = this.campaign.prod_detail?.scenarios ?? []
    } else {
      scenarios = this.campaign.test_detail?.scenarios ?? []
    }
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.CURRENT_VALUE, scenarios)
  }

  @Watch('isOpenStartSettings')
  openStartSettingModal() {
    if (this.openStartSettings && !this.isStoryFirstPush) {
      console.log(this.openStartSettings, 'openStartSettings')
      this.modalStartVisible = true
    }
  }

  mounted() {
    window.addEventListener('message', (event) => this.handleMessage(event))
    window.addEventListener('keydown', this.handleKeyPress)
  }

  beforeUnmount() {
    window.removeEventListener('message', (event) => this.handleMessage(event))
    window.removeEventListener('keydown', this.handleKeyPress)
  }

  created() {
    this.labelAllUser = this.$t('label.all_users')
    this.openStartSettingModal()
  }
}
