<template>
  <div class="row q-mt-md">
    <div class="col-6">
      <q-list dense separator>
        <q-toolbar class="bg-green text-white shadow-2">
          <q-toolbar-title class="q-ml-md">{{ $t('label.story.eligible') }}</q-toolbar-title>
        </q-toolbar>
        <q-item clickable v-ripple v-for="(item, index) in cardOrs" :key="index">
          <q-item-section class="q-ml-md">
            <q-item-label v-if="item.answerId == 'any'" class="title-any"
              >{{ item.title }}: {{ $t('label.all') }}</q-item-label
            >
            <q-item-label v-else class="title-not-any">--{{ item.title }}: {{ item.answerTitle }}</q-item-label>
            <q-item-label class="sub-title">サイズ: {{ item.totalUsers }}</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-btn
              size="8px"
              flat
              round
              color="white"
              text-color="green"
              icon="add"
              class="dotted-border"
              @click="onUnSelectedOr(item, index)"
              style="border-color: #4caf50"
            ></q-btn>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
    <div class="col-1 q-my-auto">
      <div class="q-gutter-sm text-center">
        <q-icon name="swap_horiz" color="grey" size="32px" />
      </div>
    </div>
    <div class="col-5">
      <q-list dense separator>
        <q-toolbar class="bg-purple text-white shadow-2">
          <q-toolbar-title class="q-ml-md">{{ $t('label.story.not_eligible') }}</q-toolbar-title>
        </q-toolbar>
        <q-item clickable v-ripple v-for="(item, index) in cardNots" :key="index">
          <q-item-section class="q-ml-md">
            <q-item-label v-if="item.answerId == 'any'" class="title-any"
              >{{ item.title }}: {{ $t('label.all') }}</q-item-label
            >
            <q-item-label v-else class="title-not-any">--{{ item.title }}: {{ item.answerTitle }}</q-item-label>
            <q-item-label class="sub-title">サイズ: {{ item.totalUsers }}</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-btn
              size="8px"
              flat
              round
              color="white"
              text-color="primary"
              icon="undo"
              class="dotted-border"
              @click="onUnSelectedNot(item, index)"
              style="border-color: #4956e3"
            ></q-btn>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { EScenarioResourceType, IPushAudience, IScenarioAnswer } from '@/utils/types'
import PushCampaignMixin from '@/components/push-campaign/mixins/PushCampaignMixin.vue'
import { ICard } from 'bot-flow-maker/src/types'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class ScenarioAnswerOrNotSetting extends mixins(PushCampaignMixin) {
  @Prop({})
  modelValue: IPushAudience = {
    card_or: [],
    card_not: [],
  }

  @Prop({})
  isTestMode!: boolean

  @Prop()
  scenarios!: ICard[]

  cardOrs: IScenarioAnswer[] = []
  cardNots: IScenarioAnswer[] = []

  get value() {
    return this.modelValue
  }

  set value(value: IPushAudience) {
    this.$emit('update:modelValue', value)
  }

  @Watch('value', { immediate: true })
  async onRefresh() {
    this.cardOrs = this.value.card_or ?? []
    this.cardNots = this.value.card_not ?? []
    this.updateApi()
  }

  @Watch('selectedAppId', { immediate: true })
  async updateApi() {
    if (!this.selectedAppId) {
      return
    }

    this.cardOrs = []
    if (this.scenarios) {
      const items = this.scenarios.filter(
        (item) => item.cardType === EScenarioResourceType.question || item.cardType === EScenarioResourceType.goal
      )
      for (let index = 0; index < items.length; index++) {
        const item = items[index]
        for (let j = 0; j < item.answers.length; j++) {
          const el = item.answers[j]
          if (!this.onDisabledNot(item.id, el.id)) {
            this.cardOrs.push({
              id: item.id,
              uniqueId: item.uniqueId,
              title: item.title,
              cardType: item.cardType,
              totalUsers: el.totalUsers,
              answerId: el.id,
              answerTitle: el.title,
            })
          }
        }
      }

      this.value.card_or = this.cardOrs
    }
  }

  onDisabledOr(id: string, answerId: string) {
    const checkExist = this.value?.card_or?.find((or) => or.id === id && or.answerId === answerId)
    if (checkExist) {
      return true
    }
  }

  onDisabledNot(id: string, answerId: string) {
    const checkExist = this.value?.card_not?.find((not) => not.id === id && not.answerId === answerId)
    if (checkExist) {
      return true
    }
  }

  async onUnSelectedOr(row: IScenarioAnswer, index: number) {
    if (!this.value.card_not) {
      this.value.card_not = []
    }

    if (this.value.card_or) {
      this.value.card_or.splice(index, 1)
      this.value.card_not.push(row)
      this.onRefresh()
    }
  }

  async onUnSelectedNot(row: IScenarioAnswer, index: number) {
    if (!this.value.card_or) {
      this.value.card_or = []
    }
    if (this.value.card_not) {
      this.value.card_not.splice(index, 1)
      this.value.card_or.push(row)
      this.onRefresh()
    }
  }
}
</script>
<style lang="scss" scoped>
.horizontal-dotted-line {
  border-bottom: 1px dotted grey;
}
.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 23.17px;
  text-align: left;
}
.q-toolbar {
  min-height: 36px;
}
.q-toolbar__title {
  font-size: 18px;
}
:deep(.q-btn.dotted-border .q-icon) {
  font-size: 1.25em;
}
.title-any {
  font-size: 10px !important;
  font-weight: 700;
  text-align: left;
  color: #8f969e;
}

.title-not-any {
  font-weight: 400;
  text-align: left;
  color: #8f969e;
  font-size: 12px !important;
}

.sub-title {
  font-size: 8px !important;
  font-weight: 400 !important;
  line-height: 8.69px !important;
  text-align: left;
  color: #8f969e;
}
</style>
