import { render } from "./Initial.vue?vue&type=template&id=6f1560ae&scoped=true"
import script from "./Initial.vue?vue&type=script&lang=ts"
export * from "./Initial.vue?vue&type=script&lang=ts"

import "./Initial.vue?vue&type=style&index=0&id=6f1560ae&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-6f1560ae"

export default script
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtnGroup,QBtn});
