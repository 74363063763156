
import { Options, mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { EScenarioResourceType, IPushAudience, IScenarioAnswer } from '@/utils/types'
import PushCampaignMixin from '@/components/push-campaign/mixins/PushCampaignMixin.vue'
import { ICard } from 'bot-flow-maker/src/types'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class ScenarioAnswerOrNotSetting extends mixins(PushCampaignMixin) {
  @Prop({})
  modelValue: IPushAudience = {
    card_or: [],
    card_not: [],
  }

  @Prop({})
  isTestMode!: boolean

  @Prop()
  scenarios!: ICard[]

  cardOrs: IScenarioAnswer[] = []
  cardNots: IScenarioAnswer[] = []

  get value() {
    return this.modelValue
  }

  set value(value: IPushAudience) {
    this.$emit('update:modelValue', value)
  }

  @Watch('value', { immediate: true })
  async onRefresh() {
    this.cardOrs = this.value.card_or ?? []
    this.cardNots = this.value.card_not ?? []
    this.updateApi()
  }

  @Watch('selectedAppId', { immediate: true })
  async updateApi() {
    if (!this.selectedAppId) {
      return
    }

    this.cardOrs = []
    if (this.scenarios) {
      const items = this.scenarios.filter(
        (item) => item.cardType === EScenarioResourceType.question || item.cardType === EScenarioResourceType.goal
      )
      for (let index = 0; index < items.length; index++) {
        const item = items[index]
        for (let j = 0; j < item.answers.length; j++) {
          const el = item.answers[j]
          if (!this.onDisabledNot(item.id, el.id)) {
            this.cardOrs.push({
              id: item.id,
              uniqueId: item.uniqueId,
              title: item.title,
              cardType: item.cardType,
              totalUsers: el.totalUsers,
              answerId: el.id,
              answerTitle: el.title,
            })
          }
        }
      }

      this.value.card_or = this.cardOrs
    }
  }

  onDisabledOr(id: string, answerId: string) {
    const checkExist = this.value?.card_or?.find((or) => or.id === id && or.answerId === answerId)
    if (checkExist) {
      return true
    }
  }

  onDisabledNot(id: string, answerId: string) {
    const checkExist = this.value?.card_not?.find((not) => not.id === id && not.answerId === answerId)
    if (checkExist) {
      return true
    }
  }

  async onUnSelectedOr(row: IScenarioAnswer, index: number) {
    if (!this.value.card_not) {
      this.value.card_not = []
    }

    if (this.value.card_or) {
      this.value.card_or.splice(index, 1)
      this.value.card_not.push(row)
      this.onRefresh()
    }
  }

  async onUnSelectedNot(row: IScenarioAnswer, index: number) {
    if (!this.value.card_or) {
      this.value.card_or = []
    }
    if (this.value.card_not) {
      this.value.card_not.splice(index, 1)
      this.value.card_or.push(row)
      this.onRefresh()
    }
  }
}
