
import { Watch } from 'vue-property-decorator'
import { mixins, Options } from 'vue-class-component'
import CLoading from '@/components/common/ui/CLoading.vue'
import { maska } from 'maska'
import BaseFormMixin from '@/components/common/mixins/BaseFormMixin.vue'
import { ICampaign, IInitialCampaign, IPopup, IPushCampaign, IStory } from '@/utils/types'
import PopupDeliver from '@/components/story/trigger/PopupDeliver.vue'
import TagInputContent from '@/components/common/ui/TagInputContent.vue'
import cloneDeep from 'lodash/cloneDeep'
import { ACTION_CAMPAIGN, ACTION_INITIAL, ACTION_POPUP, ACTION_PUSH, ACTION_STORY } from '@/store/actions'
import { constant, DELIVERY_TARGET_TYPE, STARTING_POINT_TYPE, TRIGGER_TYPE, UNIT_TYPE } from '@/utils/constants'
import ChooseTriggerType from '@/components/story/common/ChooseTriggerType.vue'
import Stepper from '@/components/story/common/Stepper.vue'
import { ObjectUtils } from '@/utils/objects'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import Audience from '@/components/story/trigger/Audience.vue'

@Options({
  components: { Audience, Stepper, ChooseTriggerType, TagInputContent, PopupDeliver, CLoading, TestModeSelector },
  directives: { maska },
  emits: [],
  async beforeRouteLeave() {
    if (!this) {
      return
    }
    this.isCancel = false
    return await this.checkDraftValue()
  },
})
export default class StorySettingForm extends mixins(BaseFormMixin) {
  loading = false
  step = 0
  storyStep = 1
  isSetting = false
  isTestMode = false
  isCopy = false
  isOpenStartSettings = false

  componentKey = 0
  selectedPushIndex = 0
  triggerOptions = [
    {
      label: 'LINE誘導',
      value: TRIGGER_TYPE.POPUP_DELIVER,
    },
    {
      label: 'セグメント',
      value: TRIGGER_TYPE.AUDIENCE,
    },
    {
      label: '一斉配信',
      value: TRIGGER_TYPE.ALL,
    },
  ]

  form: IStory = {
    _id: '',
    app_id: '',
    display_id: '',
    story_name: '',
    is_active: false,
    is_test_mode: false,
    trigger_type: '',
    campaign: {},
    initial: {},
    push: {},
    pushes: [],
    popups: [],
  }

  blankPush: IPushCampaign = {
    _id: '',
    title: '',
    app_id: this.appId,
    delivered: 0,
    is_active: false,
    is_test_mode: false,
    audience_size: 0,
    prod_detail: {
      starting_point: STARTING_POINT_TYPE.SCENARIO_START,
      unit_type: UNIT_TYPE.MESSAGE_QA_UNIT,
      delivery_target_type: DELIVERY_TARGET_TYPE.RESPONDING_USERS,
      direction_type: 1,
      audience: {
        card_or: [],
        card_not: [],
        audience_or: [],
        audience_not: [],
        unreached_users: [],
      },

      frequency: {
        active: false,
        time_per_user: 1,
      },
      schedule: {
        schedule_type: 'multi_time',
        schedule_by_type: 'by_delay_date',
        delay_day: 0,
        day_of_week: 0,
        day_of_month: 1,
        date: '',
        time: '',
      },
      scenarios: [],
    },
    test_detail: {
      starting_point: STARTING_POINT_TYPE.SCENARIO_START,
      unit_type: UNIT_TYPE.MESSAGE_QA_UNIT,
      delivery_target_type: DELIVERY_TARGET_TYPE.RESPONDING_USERS,
      direction_type: 1,
      audience: {
        card_or: [],
        card_not: [],
        audience_or: [],
        audience_not: [],
        unreached_users: [],
      },
      frequency: {
        active: false,
        time_per_user: 1,
      },
      schedule: {
        schedule_type: '',
        schedule_by_type: '',
        delay_day: 0,
        day_of_week: 0,
        day_of_month: 1,
        date: '',
        time: '',
      },
      scenarios: [],
    },
  }

  get requiredRule() {
    return {
      storyName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.story.story_name'),
      }),
    }
  }

  get triggerTypeName() {
    for (const item of this.triggerOptions) {
      if (this.form.trigger_type === item.value) {
        return item.label
      }
    }
    return ''
  }

  get selectedStoryId() {
    return this.$route.params.story_id
  }

  get saveBtnText() {
    if (this.step >= 3) {
      return this.$t('reflect')
    }
    return this.$t('save')
  }

  get stateEdit() {
    if (!this.initForm.initial?.prod_detail || !this.form.initial?.prod_detail) {
      return false
    }
    return (
      Object.keys(this.initForm.initial?.prod_detail?.scenarios).length &&
      ObjectUtils.isDifference(this.form.initial?.prod_detail?.scenarios, this.initForm.initial?.prod_detail?.scenarios)
    )
  }

  onNextStep() {
    this.step++
  }

  @Watch('step', { immediate: true })
  handleStoryStep() {
    if (this.form.popups?.length) {
      this.storyStep = 2
    }
    if (this.form.initial?.title) {
      this.storyStep = 3
    }
    if (this.form.pushes?.length) {
      this.storyStep = 4
    }
    // console.log(this.storyStep, 'storyStep')
  }

  nextStep() {
    if (this.step <= this.storyStep) {
      if (this.storyStep < 4) {
        this.step = this.storyStep + 1
      } else {
        this.step = 4
      }
      if (this.step > 3) {
        this.form.pushes = [...(this.form.pushes ?? []), cloneDeep(this.blankPush)]
        this.selectedPushIndex = this.form.pushes.length - 1
      }
    } else {
      if (this.step === 1) {
        this.$refs.formRef
          .validate()
          .then(async (success: boolean) => {
            if (!success) {
              return
            }

            this.step++
          })
          .catch((error: unknown) => {
            console.log('error', error)
          })
      }
      if (this.step === 2 || this.step === 3) {
        if (this.form.trigger_type === TRIGGER_TYPE.POPUP_DELIVER) {
          this.$refs.popupDeliver.validate(false)
        }
      }
      if ((this.form.trigger_type === 'audience' && this.step >= 2) || this.step >= 3) {
        this.form.pushes = [...(this.form.pushes ?? []), cloneDeep(this.blankPush)]
        this.selectedPushIndex = this.form.pushes.length - 1
      }
    }
  }

  onSubmit() {
    if (this.step <= 1) {
      this.$refs.formRef
        .validate()
        .then(async (success: boolean) => {
          if (!success) {
            return
          }

          await this.doSave()
        })
        .catch((error: unknown) => {
          console.log('error', error)
        })
    } else {
      console.log(this.step, 'onSubmit step step')

      if (this.form.trigger_type === TRIGGER_TYPE.POPUP_DELIVER) {
        this.$refs.popupDeliver.validate(true)
      } else if (this.form.trigger_type === 'audience') {
        this.$refs.audience.validate(true)
      }
    }
  }

  async doSave() {
    console.log('doSave')

    this.loading = true
    const { dispatch } = this.$store
    let item: IStory = {}

    this.form.app_id = this.appId
    if (this.form._id) {
      await dispatch(ACTION_STORY.UPDATE, {
        _id: this.form._id,
        ...this.form,
      })
    } else {
      item = await dispatch(ACTION_STORY.ADD, {
        ...this.form,
      })
      if (item) {
        this.form._id = item._id
      }
    }

    if (this.form.trigger_type === TRIGGER_TYPE.POPUP_DELIVER) {
      if (this.form.campaign?.prod_detail) {
        this.form.campaign.story_id = this.form._id
        await this.saveCampaign(this.form.campaign)
      }

      if (this.form.initial?.title) {
        this.form.initial.story_id = this.form._id
        await this.saveInitial(this.form.initial)
      }
    }

    if (this.form.pushes) {
      await this.savePushes(this.form.pushes)
    }

    await this.initialSory(this.form._id)

    this.handleStoryStep()
    this.componentKey++
    this.loading = false
    this.$q.notify({
      message: this.$t('messages.saved'),
      color: 'positive',
    })

    this.initForm = cloneDeep(this.parentForm)
    this.goto('story_setting', {
      app_id: this.appId,
      story_id: this.form._id,
      action_type: constant.ACTION_TYPE.EDIT,
    })
  }

  async saveCampaign(campaign: ICampaign) {
    if (!campaign._id) {
      campaign.app_id = this.appId
      campaign.campaign_name = this.form.story_name
    }
  }

  async saveInitial(initial: IInitialCampaign) {
    if (!initial._id) {
      initial.is_active = false
      initial.app_id = this.appId
      initial.test_detail = initial.prod_detail
    }
    initial.title = '初回メッセージ'
  }

  async savePushes(pushes: IPushCampaign[]) {
    for (const push of pushes) {
      if (!push._id) {
        push.app_id = this.appId
        push.story_id = this.form._id
        if (push.test_detail) {
          push.test_detail = {
            direction_type: push.prod_detail?.direction_type,
            scenarios: push.prod_detail?.scenarios,
            frequency: push.test_detail?.frequency,
            schedule: push.test_detail?.schedule,
            audience: push.test_detail.audience,
          }
        }
      }
    }
  }

  async onRevert() {
    this.isCancel = true
    const result = await this.checkDraftValueOnCampaign()
    if (result) {
      const initial: IInitialCampaign = await this.$store.dispatch(ACTION_INITIAL.LOAD_STORY_ITEM, this.form._id)
      if (initial) {
        this.form.initial = initial
        this.step = 3
        this.componentKey++
      }
    }
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  async initialSory(storyId) {
    if (storyId) {
      const item: IStory = await this.$store.dispatch(ACTION_STORY.LOAD_ITEM, storyId)
      if (item) {
        const pushes: IPushCampaign[] = await this.$store.dispatch(ACTION_PUSH.LOAD_STORY_ITEMS, storyId)

        if (item.trigger_type === TRIGGER_TYPE.POPUP_DELIVER) {
          const campaign: ICampaign = await this.$store.dispatch(ACTION_CAMPAIGN.LOAD_STORY_ITEM, storyId)
          if (campaign._id !== '') {
            item.campaign = campaign
            const popups: IPopup[] = await this.$store.dispatch(ACTION_POPUP.LOAD_ITEMS, {
              app_id: this.appId,
              campaign_id: campaign._id,
            })
            item.popups = popups
          }
          const initial: IInitialCampaign = await this.$store.dispatch(ACTION_INITIAL.LOAD_STORY_ITEM, storyId)
          if (initial._id !== '') {
            item.initial = initial
          }

          item.pushes = pushes
        } else if (item.trigger_type === TRIGGER_TYPE.AUDIENCE) {
          if (pushes.length > 0) {
            item.push = pushes[0]
            item.pushes = pushes
          }
        }

        this.form = { ...this.form, ...cloneDeep(item) }
      }
    }
  }

  @Watch('isTestMode', { immediate: true })
  async onChangeTestMode() {
    this.componentKey++
  }

  onDetailCopy() {
    this.isCopy = !this.isCopy
  }

  async deletePush(index) {
    if (this.form.pushes) {
      const push: IPushCampaign = this.form.pushes[index]
      if (push) {
        this.handleResetAudiences(index)
        this.form.pushes.splice(index, 1)
        this.handleCurrentStep()
        this.componentKey++

        // if (push._id) {
        //   this.$q
        //     .dialog({
        //       title: this.$t('confirm'),
        //       message: this.$t('are_you_sure_you_want_to_delete'),
        //       cancel: true,
        //       persistent: true,
        //     })
        //     .onOk(async () => {
        //       const success = await this.$store.dispatch(ACTION_PUSH.DELETE, push._id)
        //       if (success) {
        //         this.$q.notify({
        //           message: this.$t('messages.deleted'),
        //           color: 'positive',
        //         })
        //         if (this.form.pushes) {
        //           this.form.pushes.splice(index, 1)
        //           this.handleCurrentStep()
        //         }
        //       }
        //     })
        // } else {
        //   this.form.pushes.splice(index, 1)
        //   this.handleCurrentStep()
        // }
      }
    }
  }

  handleResetAudiences(index) {
    if (this.form.pushes && this.form.pushes.length > index) {
      const push = this.form.pushes[index + 1]
      if (push && push.prod_detail && push.prod_detail.audience) {
        push.prod_detail.audience.card_not = []
        push.prod_detail.audience.card_or = []
        push.prod_detail.audience.unreached_users = []
      }
      if (push && push.test_detail && push.test_detail.audience) {
        push.test_detail.audience.card_not = []
        push.test_detail.audience.card_or = []
        push.test_detail.audience.unreached_users = []
      }
    }
  }

  handleCurrentStep() {
    if (this.form.pushes && this.form.pushes.length) {
      this.step = 4
      this.selectedPushIndex = 0
    } else {
      this.step = 3
    }
  }

  openStartSettings() {
    this.isOpenStartSettings = true
  }

  async created() {
    this.loading = true

    if (this.selectedStoryId) {
      this.step = 1
    } else {
      this.step = 0
    }

    await this.initialSory(this.selectedStoryId)
    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
    this.loading = false
    if (this.form._id) {
      this.isSetting = true
    }
    this.handleStoryStep()
  }
}
